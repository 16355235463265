import { Component, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { SubscriptionManager, TranslationService } from '@peab/angular-common';
import { Translations } from 'src/app/app-translation';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/core/app.service';

@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss'],
})
export class PasswordChangeComponent implements OnInit, OnDestroy {
  private readonly _subscriptionManager = new SubscriptionManager();

  @Output() countrySelect = new EventEmitter<string>();

  get translations() {
    return this._translations.keys.common;
  }
  constructor(
    private readonly _translations: TranslationService<Translations>,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _service: AppService
  ) {}

  ngOnInit() {
    this._subscriptionManager.register(
      this._activatedRoute.data.subscribe(data => {
        this._service.setCurrentCountry(data.countryCode);
      })
    );
  }

  ngOnDestroy(): void {
    this._subscriptionManager.unsubscribe();
  }
}
