// Angular
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

// Component
import { PasswordChangeComponent } from './password-change/password-change.component';
import { PasswordChangeFormComponent } from './password-change/form/form.component';
import { PasswordChangeResponseComponent } from './password-change/response/response.component';
import { PasswordChangeStepperComponent } from './password-change/stepper/stepper.component';

// Material
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';

// PEAB
import { PeabCommonModule } from '@peab/angular-common';

const ownComponents = [
  PasswordChangeComponent,
  PasswordChangeFormComponent,
  PasswordChangeStepperComponent,
  PasswordChangeResponseComponent
];

const angular = [
  BrowserModule,
  BrowserAnimationsModule,
  CommonModule,
  FormsModule
];

const material = [
  MatButtonModule,
  MatDialogModule,
  MatIconModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatStepperModule
];

@NgModule({
  imports: [
    angular,
    material,
    PeabCommonModule,
  ],
  declarations: [
    ownComponents
  ],
  exports: [
    PeabCommonModule
  ]
})
export class ComponentModule { }
