<peab-card>
  <ng-container *ngIf="isPasswordChanged; then success; else error">
  </ng-container>
  <peab-button type="text" (click)="resetStepper()">
    {{ translations.return | translate }}
  </peab-button>
</peab-card>

<ng-template #success>
  <peab-header>
    {{ translations.resetSuccess | translate }}
  </peab-header>
  <peab-text>
    {{ translations.passwordSent | translate }}
  </peab-text>
</ng-template>

<ng-template #error>
  <peab-header>
    {{ errorTranslations.majorError | translate }}
  </peab-header>
</ng-template>
