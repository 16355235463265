// Angular
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

// Service
import { ApiService } from './services/api.service';
import { AppService } from '../core/app.service';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [ApiService, AppService]
})
export class ServiceModule {}
