// Angular
import { NgModule } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';

// Component
import { AppComponent } from './app.component';
import { DefaultRoutingComponent } from './default-routing.component';

// Module
import { AppRoutingModule } from './app-routing.module';
import { ComponentModule } from './feature/component.module';
import { ServiceModule } from './api/service.module';

@NgModule({
  imports: [AppRoutingModule, ComponentModule, ServiceModule],
  declarations: [AppComponent, DefaultRoutingComponent],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'fill' }
    },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false }
    }
  ]
})
export class AppModule {}
