import { AppService } from './core/app.service';
import {
  CountryCode,
  PageOptions,
  SubscriptionManager,
  TranslationService,
  UserInfo
} from '@peab/angular-common';
import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { finnish, norwegian, swedish } from 'src/translation';
import { fromEvent } from 'rxjs';
import { Translations } from './app-translation';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  headerOptions: PageOptions = {} as PageOptions;
  userInfo: UserInfo = {
    firstName: undefined,
    ipAddress: undefined,
    lastName: undefined,
    userId: undefined,
  };
  private readonly _subscriptionManager = new SubscriptionManager();

  constructor(
    private readonly _translation: TranslationService<Translations>,
    private readonly _router: Router,
    private readonly _elementRef: ElementRef
  ) {
    _translation.initialize({
      translations: {
        fi: finnish,
        no: norwegian,
        sv: swedish,
      },
    });
  }

  ngOnInit() {
    this.setInnerViewMobilePageHeight();

    this._subscriptionManager.register(
      fromEvent(window, 'resize')
        .pipe(debounceTime(200))
        .subscribe(() => {
          this.setInnerViewMobilePageHeight();
        })
    );

    const translations = this._translation.keys.common;

    this.headerOptions = {
      title: translations.title,
      links: [
        { label: translations.norway, routerLink: `no` },
        { label: translations.sweden, routerLink: `se` },
        { label: translations.finland, routerLink: `fi` },
      ],
      breadcrumbs: [translations.title, translations.country],
      user: this.userInfo,
      buttons: [
        {
          routerLink: 'no',
          label: translations.norway,
        },
        {
          routerLink: 'se',
          label: translations.sweden,
        },
        {
          routerLink: 'fi',
          label: translations.finland,
        },
      ],
    };
  }

  setInnerViewMobilePageHeight() {
    const viewHeight = window.innerHeight;
    this._elementRef.nativeElement.style.setProperty(
      '--mobile-page-view-height',
      `${viewHeight}px`
    );
  }

  onCountrySelected(countryCode: CountryCode) {
    this._router.navigate([countryCode]);
  }

  ngOnDestroy() {
    this._subscriptionManager.unsubscribe();
  }
}
