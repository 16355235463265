import { DefaultRoutingComponent } from './default-routing.component';
import { NgModule } from '@angular/core';
import { PasswordChangeComponent } from './feature/password-change/password-change.component';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from '@peab/angular-common';


const routes: Routes = [
  {
    path: '',
    component: DefaultRoutingComponent,
  },
  {
    path: 'no',
    data: { countryCode: 'no' },
    component: PasswordChangeComponent,
  },
  {
    path: 'se',
    data: { countryCode: 'se' },
    component: PasswordChangeComponent,
  },
  {
    path: 'fi',
    data: { countryCode: 'fi' },
    component: PasswordChangeComponent,
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
