import { ApiService } from 'src/app/api/services/api.service';
import { AppService } from 'src/app/core/app.service';
import { Component, EventEmitter, OnInit, OnDestroy, Output } from '@angular/core';
import { CountryCode, SubscriptionManager, TranslationService } from '@peab/angular-common';
import { Company } from 'src/app/api/models/Company';
import { finalize, take } from 'rxjs/operators';
import { ResetRequest } from 'src/app/api/models/ResetRequest';
import { Translations } from 'src/app/app-translation';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class PasswordChangeFormComponent implements OnInit, OnDestroy {
  companies: Company[] = [];
  companyNumber = '';
  countryCode: CountryCode = 'se';
  identificationNumber = '';
  matSpinnerDiameterSize = 25;
  isDisabled = false;

  private readonly _subscriptionManager = new SubscriptionManager();

  @Output() passwordChange = new EventEmitter<boolean>();

  get translations() {
    return this._translations.keys.common;
  }

  constructor(
    private readonly _appService: AppService,
    private readonly _apiService: ApiService,
    private readonly _translations: TranslationService<Translations>
  ) {}

  ngOnInit() {
    this._subscriptionManager.register(
      this._appService.getCurrentCountry().subscribe(countryCode => {
        this.countryCode = countryCode;
        this.getCompanies(countryCode);
      })
    );
  }

  ngOnDestroy(): void {
    this._subscriptionManager.unsubscribe();
  }

  isCountrySweden(): boolean {
    return this.countryCode === 'se';
  }

  getCompanies(countryCode: CountryCode) {
    if (this.isCountrySweden()) {
      return;
    }

    this._subscriptionManager.register(
      this._apiService.getCompanies(countryCode).subscribe(companies => {
        this.companies = companies;
      })
    );
  }

  passwordResetFormValidation(): boolean {
    return (
      this.identificationNumber === null ||
      this.identificationNumber.length < 1 ||
      (this.countryCode !== 'se' && this.companyNumber === '')
    );
  }

  onSubmit() {
    if (this.passwordResetFormValidation()) {
      return;
    }

    this.isDisabled = true;

    const resetRequest: ResetRequest = {
      socialSecurityNumber: this.identificationNumber,
      companyNumber: this.companyNumber,
      employeeNumber: this.identificationNumber,
      countryCode: this.countryCode,
    };

    this._apiService
      .resetPasswordApi(resetRequest)
      .pipe(
        finalize(() => (this.isDisabled = false))
      )
      .subscribe(
        (success) => {
          this.passwordChange.emit(true);
        },
        (error) => {
          this.passwordChange.emit(false);
        }
      );
  }
}
