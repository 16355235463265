export default {
    common: {
        company: 'Selskap',
        companyTitle: 'Peab',
        confirmDialog: 'Er du sikker på at du vil tilbakestille passordet?',
        country: 'Norge',
        createNewPassword: 'Opprett nytt passord',
        description: 'Har du glemt passordet eller er kontoen låst? Velg selskap, skriv inn ansattnummer og klikk på Send.',
        identificationNumber: 'Ansattnummer',
        passwordReset: 'Opprett nytt passord',
        placeholderidentificationNumber: 'Skriv inn ansattnummer',
        reset: 'Tilbakestille',
        resetSuccess: 'Ditt passord er gjenopprettet',
        title: 'Password reset',
        settings: 'Innstillinger',
        enterInformation: 'Fyll ut detaljene',
        passwordIsReset: 'Reset passord',
        return: 'Tilbake',
        sweden: 'Sverige',
        norway: 'Norge',
        finland: 'Suomi',
        passwordSent: 'Et nytt passord er sendt til deg via tekstmelding.'
    },
    errormessages: {
        majorError: 'Noe gikk galt',
        pageNotFound: 'Siden kan ikke bli funnet',
    }
};
