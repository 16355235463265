import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-default-routing',
  template: '',
  styles: [],
})
export class DefaultRoutingComponent implements OnInit {
  constructor(private readonly _router: Router) {}

  ngOnInit(): void {
    let countrySelected = localStorage.getItem('PeabPasswordResetCountrySelected');

    if (!['se', 'no', 'fi'].includes(countrySelected)) {
      countrySelected = 'se';
    }

    this._router.navigate([countrySelected]);
  }
}
