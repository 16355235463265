<mat-horizontal-stepper #stepper linear>
  <mat-step>
    <ng-template matStepLabel>
      {{ translations.enterInformation | translate }}
    </ng-template>
    <app-form (passwordChange)="onPasswordChanged($event)"></app-form>
  </mat-step>

  <mat-step>
    <ng-template matStepLabel>
      {{ translations.passwordIsReset | translate }}
    </ng-template>
    <app-response
      [isPasswordChanged]="isPasswordChanged"
      (stepperReset)="onStepperReset()"
    ></app-response>
  </mat-step>
</mat-horizontal-stepper>
