import { Injectable } from '@angular/core';
import { CountryCode, LanguageCode, TranslationService } from '@peab/angular-common';
import { Observable, BehaviorSubject } from 'rxjs';
import { Translations } from '../app-translation';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  currentCountryCode: CountryCode;
  private countryCode: BehaviorSubject<CountryCode>;
  private defaultCountryCode: CountryCode = 'se';

  constructor(private readonly _translations: TranslationService<Translations>) {
    this.countryCode = new BehaviorSubject<CountryCode>(this.defaultCountryCode);
  }

  getCurrentCountry(): Observable<CountryCode> {
    return this.countryCode.asObservable();
  }

  setCurrentCountry(countryCode: CountryCode) {
    this.countryCode.next(countryCode);
    const languageCode = this.getLanguageCode(countryCode);
    this.setLanguage(languageCode);
    this.saveSelectedCountryCodeToLocaleStorage(countryCode);
  }

  setLanguage(languageCode: LanguageCode) {
    this._translations.selectLanguage(languageCode);
  }

  saveSelectedCountryCodeToLocaleStorage(countryCode: CountryCode) {
    localStorage.setItem('PeabPasswordResetCountrySelected', countryCode);
  }

  getLanguageCode(countryCode: CountryCode): LanguageCode {
    switch (countryCode) {
      case 'no': {
        return 'no';
      }
      case 'fi': {
        return 'fi';
      }
      default: {
        return 'sv';
      }
    }
  }
}
