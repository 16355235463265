<peab-card>
  <peab-header> 
    {{ translations.enterInformation | translate }}
  </peab-header>
  <form (submit)="onSubmit()">
    <div>
      <mat-form-field>
        <mat-label>
          {{ translations.identificationNumber | translate }}
        </mat-label>
        <input 
          matInput
          required 
          type="number" 
          name="identificationNumber"
          [(ngModel)]="identificationNumber" 
        />
        <mat-hint>
          {{ translations.placeholderidentificationNumber | translate }}
        </mat-hint>
      </mat-form-field>
    </div>
    
    <ng-container *ngIf="!isCountrySweden()">
      <div>
        <mat-form-field>
          <mat-label>
            {{ translations.company | translate }}
          </mat-label>
          <mat-select 
            required 
            name="identificationNumber"
            [(ngModel)]="companyNumber"
          >
            <mat-option *ngFor="let company of companies" [value]="company.companyNumber">
              {{ company.name }}
            </mat-option>
          </mat-select>
          <ng-container matSuffix *ngIf="companies.length === 0">
            <mat-spinner [diameter]="matSpinnerDiameterSize"></mat-spinner>
          </ng-container>
        </mat-form-field>
      </div>
    </ng-container>
    <peab-button [disabled]="isDisabled">
      {{ translations.reset | translate }}
    </peab-button>
  </form>
</peab-card>
