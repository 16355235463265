export default {
    common: {
        company: 'Bolag',
        companyTitle: 'Peab',
        confirmDialog: 'Är du säker på att du vill återställa lösenordet?',
        country: 'Sverige',
        createNewPassword: 'Skapa nytt lösneord',
        description: 'Har du glömt ditt lösenord eller låst kontot så kan du återställa det här genom att skapa ett nytt lösenord.',
        identificationNumber: 'Personnummer',
        passwordReset: 'Lösenordsåterställning',
        placeholderidentificationNumber: 'Skriv in 10 siffror',
        reset: 'Återställ',
        resetSuccess: 'Ditt lösenord är nu återställt',
        title: 'Password reset',
        settings: 'Inställningar',
        enterInformation: 'Fyll i dina uppgifter',
        passwordIsReset: 'Lösenord återställt',
        return: 'Tillbaka',
        sweden: 'Sverige',
        norway: 'Norge',
        finland: 'Suomi',
        passwordSent: 'Ett nytt lösenord är skickat till dig via sms.'
    },
    errormessages: {
        majorError: 'Något har gått fel... Försök igen eller kontakta support.',
        pageNotFound: 'Sidan kan inte hittas'
    }
};
