import { Component, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { TranslationService } from '@peab/angular-common';
import { Translations } from 'src/app/app-translation';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class PasswordChangeStepperComponent {
  isPasswordChanged: boolean;

  @ViewChild('stepper') pwResetStepper: MatStepper;

  get translations() {
    return this._translations.keys.common;
  }

  constructor(private readonly _translations: TranslationService<Translations>) {}

  nextStep() {
    this.pwResetStepper.selected.completed = true;
    this.pwResetStepper.next();
  }

  resetStepper() {
    this.pwResetStepper.reset();
  }

  onPasswordChanged(changedPassword: boolean) {
    this.nextStep();
    this.isPasswordChanged = changedPassword;
  }

  onStepperReset() {
    this.resetStepper();
  }
}
