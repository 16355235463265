<peab-grid>
  <peab-grid-cell columns="6">
    <peab-header>
      {{ translations.passwordReset | translate }}
    </peab-header>
    <peab-text>
      {{ translations.description | translate }}
    </peab-text>
    <app-stepper></app-stepper>
  </peab-grid-cell>
</peab-grid>
