import { CountryCode } from '@peab/angular-common';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Company } from '../models/Company';
import { Injectable } from '@angular/core';
import { ResetRequest } from '../models/ResetRequest';
import { Observable } from 'rxjs';

@Injectable()
export class ApiService {
  private readonly localBase = environment.apiurl;

  constructor(private readonly http: HttpClient) {}

  getCompanies(country: CountryCode): Observable<Company[]> {
    const url = `${this.localBase}Company/${country}`;
    return this.http.get<Company[]>(url);
  }

  resetPasswordApi(resetReq: ResetRequest): Observable<object> {
    const url = `${this.localBase}Employee/Password`;
    return this.http.put(url, resetReq);
  }
}
