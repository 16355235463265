export default {
    common: {
        company: 'Yritys',
        companyTitle: 'Peab',
        confirmDialog: 'Haluatko varmasti palauttaa salasanan?',
        country: 'Suomi',
        createNewPassword: 'Luo uusi salasana',
        description: 'Jos olet unohtanut salasanasi tai tilisi on mennyt lukkoon, voit nollata salasanasi luomalla uuden salasanan.',
        identificationNumber: 'Työntekijänumero',
        passwordReset: 'Nollaa salasana',
        placeholderidentificationNumber: 'Anna työtunnuksesi',
        reset: 'Lähetä',
        resetSuccess: 'Salasanasi on lähetetty',
        title: 'Password reset',
        settings: 'Asetukset',
        enterInformation: 'Täytä tietosi',
        passwordIsReset: 'Nollaa salasana',
        return: 'takaisin',
        sweden: 'Sverige',
        norway: 'Norge',
        finland: 'Suomi',
        passwordSent: 'Uusi salasana on lähetetty sinulle tekstiviestillä.'
    },
    errormessages: {
        majorError: 'Jotain meni pieleen',
        pageNotFound: 'Sivua ei löydy'
    }
};
