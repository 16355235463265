import { Component, Input, EventEmitter, Output } from '@angular/core';
import { TranslationService } from '@peab/angular-common';
import { Translations } from 'src/app/app-translation';

@Component({
  selector: 'app-response',
  templateUrl: './response.component.html',
  styleUrls: ['./response.component.scss'],
})
export class PasswordChangeResponseComponent {
  @Input() isPasswordChanged: boolean;
  @Output() stepperReset = new EventEmitter();

  get translations() {
    return this._translations.keys.common;
  }

  get errorTranslations() {
    return this._translations.keys.errormessages;
  }

  constructor(
    private readonly _translations: TranslationService<Translations>
  ) {}

  resetStepper() {
    this.stepperReset.emit();
  }
}
